import React, {useState} from 'react';
import Box from 'src/DesignSystem/Box/Box';
import LabelField from './LabelField';
import {TAX_RETURN_STATUS_LABELS} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS, themmeColor} from 'src/constants/constants';
import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import ExtraCountCell from './ExtraCountCell';
import MultiReturnStatusUpdatePopup from './MultiReturnStatusUpdatePopUp';
import UpdateReturnStatus from './UpdateReturnStatus';
import { stringClipper } from 'src/common/utils';

interface ReturnStatusUpdateProps {
  taxReturnDetails: {
    businessReturn: TaxReturn;
    indReturn: TaxReturn;
    multiReturnStatusUpdateData: {
      return_id: number;
      return_type: TaxReturnType;
      name: string;
      status: TAX_FILING_STATUS;
    }[];
  };
  totalReturns: number;
  taxReturnsLoaded: boolean;
  loading: boolean;
  taxFilingStatusPopUp: boolean;
  setTaxFilingStatusPopUp: (state: boolean) => void;
  onUpdateTaxFilingStatus: (
    returnId: number,
    returnType: TaxReturnType,
    newId: TAX_FILING_STATUS,
  ) => void;
}

const ReturnStatusUpdate = ({
  taxReturnDetails,
  totalReturns,
  taxReturnsLoaded,
  loading,
  taxFilingStatusPopUp,
  setTaxFilingStatusPopUp,
  onUpdateTaxFilingStatus,
}: ReturnStatusUpdateProps) => {
    const [multiReturnStatusPopUp, setMultiReturnStatusPopUp] = useState(false);
    const [selectedReturn, setSelectedReturn] = useState<{
    returnId: number;
    returnType: TaxReturnType;
    } | null>(null);

    const getSelectedReturnStatus = () => {
    return (
        taxReturnDetails.multiReturnStatusUpdateData.filter((data) => {
        return (
            data.return_id === selectedReturn?.returnId &&
            data.return_type === selectedReturn?.returnType
        );
        })[0]?.status ?? TAX_FILING_STATUS.REJECTED_FROM_IRS
    );
    };

    const getSelectedReturnName = () => {
    return (
        taxReturnDetails.multiReturnStatusUpdateData.filter((data) => {
        return (
            data.return_id === selectedReturn?.returnId &&
            data.return_type === selectedReturn?.returnType
        );
        })[0]?.name ?? ''
    );
    };

    return (
    <div>
        <Box
        backgroundColor="#0D0F0F0D"
        style={{
            borderColor: themmeColor.silver,
            gap: '8px',
            padding: 12,
            alignItems: 'center',
        }}>
        {taxReturnDetails.indReturn && (
            <LabelField
            label="PERSONAL (RS)"
            value={
                TAX_RETURN_STATUS_LABELS[taxReturnDetails.indReturn?.cpa_status]
                ?.label
            }
            onClick={() => {
                setTaxFilingStatusPopUp(true);
                setSelectedReturn({
                returnId: taxReturnDetails.indReturn?.return_id,
                returnType: TaxReturnType.INDIVIDUAL,
                });
            }}
            backgroundColor={
                TAX_RETURN_STATUS_LABELS[taxReturnDetails.indReturn?.cpa_status]
                ?.backColor
            }
            valueColor={
                TAX_RETURN_STATUS_LABELS[taxReturnDetails.indReturn?.cpa_status]
                ?.textColor
            }
            />
        )}
        {taxReturnDetails.businessReturn && (
            <LabelField
            label={stringClipper(taxReturnDetails.businessReturn.business_name ?? 'Business return', 12)}
            value={
                TAX_RETURN_STATUS_LABELS[
                taxReturnDetails.businessReturn.cpa_status
                ]?.label
            }
            onClick={() => {
                setTaxFilingStatusPopUp(true);
                setSelectedReturn({
                returnId: taxReturnDetails.businessReturn?.return_id,
                returnType: TaxReturnType.BUSINESS,
                });
            }}
            backgroundColor={
                TAX_RETURN_STATUS_LABELS[
                taxReturnDetails.businessReturn?.cpa_status
                ]?.backColor
            }
            valueColor={
                TAX_RETURN_STATUS_LABELS[
                taxReturnDetails.businessReturn?.cpa_status
                ]?.textColor
            }
            />
        )}
        {totalReturns > 2 && (
            <ExtraCountCell
            onClick={() => setMultiReturnStatusPopUp(true)}
            value={totalReturns - 2}
            />
        )}
        </Box>
        {selectedReturn && (
        <UpdateReturnStatus
            key={`returnStatus-${selectedReturn?.returnId}-${taxFilingStatusPopUp}`}
            isOpen={taxFilingStatusPopUp}
            onClose={() => setTaxFilingStatusPopUp(false)}
            returnName={getSelectedReturnName()}
            returnId={selectedReturn?.returnId}
            returnType={selectedReturn?.returnType}
            currentReturnStatusId={getSelectedReturnStatus()}
            loading={loading}
            onUpdate={onUpdateTaxFilingStatus}
        />
        )}
        {taxReturnsLoaded && (
        <MultiReturnStatusUpdatePopup
            key={`multiReturnStatusPopup-${multiReturnStatusPopUp}`}
            isOpen={multiReturnStatusPopUp}
            onClose={() => setMultiReturnStatusPopUp(false)}
            dataArray={taxReturnDetails.multiReturnStatusUpdateData}
            setTaxFilingStatusPopUp={setTaxFilingStatusPopUp}
            setSelectedReturn={setSelectedReturn}
        />
        )}
    </div>
    );
};

export default ReturnStatusUpdate;
