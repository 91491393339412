import React, {useCallback, useEffect, useMemo, useState} from 'react';
import InputWrapper, {
  InputWrapperBaseType,
} from '../components/InputWrapper/InputWrapper';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import DownIcon from 'src/icons/DownIcon';
import Option from '../components/Option/Option';
import Text from '../Text/Text';

interface RadioOptionType {
  label: any;
  value: any;
}

interface RadioFieldProps extends InputWrapperBaseType {
  options: RadioOptionType[];
  value: any;
  onChangeValue: (newValue: any) => void;
}

const RadioField = ({
  title,
  validators,
  options = [],
  value,
  onChangeValue,
}: RadioFieldProps) => {
  const selectedOption = useMemo(() => {
    return options.find((op) => op.value === value) ?? null;
  }, [options, value]);

  const [_selectedValue, setSelectedValue] = useState(value);

  const [optionsPopup, setOptionsPopup] = useState(false);

  const onClickOptions: React.MouseEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setOptionsPopup(true);
      e.currentTarget.blur();
    }, []);

  const onClosePopup = useCallback(() => {
    setOptionsPopup(false);
  }, []);

  useEffect(() => {
    setSelectedValue(value);
  }, [optionsPopup]);

  const onSelect = useCallback((newValue: any) => {
    setSelectedValue(newValue);
  }, []);

  const onDeselect = useCallback(() => {}, []);

  return (
    <>
      <InputWrapper
        title={title}
        type="text"
        onClick={onClickOptions}
        value={selectedOption?.label ?? 'Select one'}
        rightIcon={<DownIcon />}
        validators={validators}
        actualValue={value}
      />

      <PopUp
        primaryButtonTitle="Change"
        primaryButtonOnClick={() => {
          onChangeValue(_selectedValue);
          onClosePopup();
        }}
        primaryButtonDisabled={
          _selectedValue === null || _selectedValue === undefined
        }
        secondaryButtonTitle="Cancel"
        secondaryButtonOnClick={() => {
          onClosePopup();
        }}
        style={{width: 400}}
        isOpen={optionsPopup}
        onClose={onClosePopup}>
        <Text
          text={title ?? 'Choose one'}
          type={Text.TEXT_TYPES.L}
          fontWeight={Text.FONT_WEIGHTS.SemiBold}
          containerStyle={{marginBottom: 12}}
        />
        <div style={{maxHeight: 400, overflowY: 'auto'}}>
          {options.map(({label, value}, index) => {
            return (
              <Option
                key={`${value}-${index}`}
                isSingle
                checked={_selectedValue === value}
                label={label}
                value={value}
                onSelect={onSelect}
                onDeselect={onDeselect}
              />
            );
          })}
        </div>
      </PopUp>
    </>
  );
};

export default RadioField;
